
import ogPagesMixins from '@/mixins/ogPagesMixins'
export default {
  name: 'PagesErrors404',
  mixins: [ogPagesMixins],
  data() {
    return {
      seoTitle: '404 | Мегасреда',
      seoDescription:
        'Страница устарела, была удалена или не существовала вовсе',
    }
  },
}
